@import 'src/styles/z-indices.scss';
@import 'src/styles/variables/navigation.module.scss';
@import 'src/styles/mixins/min-width.scss';
@import 'src/styles/variables/havenlyBreakpoints.scss';

.MessagingWrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  right: -100%;
  background-color: white;
  padding-top: $navHeight;
  transition: 1s;
  top: 0;
  z-index: 11;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);

  @include min-width(large-tablet) {
    max-width: 475px;
  }

  &.isOpen {
    right: 0;
    transition: 1s;
    overflow-y: hidden;
  }

  .Frame {
    border: 0;
    height: 100%;
    width: 100%;
  }
}
