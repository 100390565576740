/*
  This mixin is a fork of bootstrap's button-variant mixin. The main difference is that
  this allows for adding custom variables for the hover and disabled states
*/
@mixin custom-button-variant(
  // Normal color set
  $color,
  $background,
  $border,
  $boxShadow,

  // Hover color set
  $hoverColor,
  $hoverBackground,
  $hoverBorder,
  $hoverBoxShadow,

  // Disabled color set
  $disabledColor,
  $disabledBackground,
  $disabledBorder,
  $disabledBoxShadow,
) {
  color: $color;
  background-color: $background;
  border-color: $border;
  box-shadow: $boxShadow;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $hoverColor;
    background-color: $hoverBackground;
    border-color: $hoverBorder;
    box-shadow: $hoverBoxShadow;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $disabledBackground;
      border-color: $disabledBorder;
      box-shadow: $disabledBoxShadow;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}
