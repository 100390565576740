/* stylelint-disable no-invalid-position-at-import-rule */
// contents of https://static.havenly.com/fonts/font-faces.css
@font-face {
  font-display: swap;
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 300;
  src:
    local('GT-Walsheim-Regular-Light'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Light.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Light.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'GT Walsheim';
  font-style: italic;
  font-weight: 300;
  src:
    local('GT-Walsheim-Regular-Light-Oblique'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Light-Oblique.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Light-Oblique.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 400;
  src:
    local('GT-Walsheim-Regular'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Regular.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'GT Walsheim';
  font-style: italic;
  font-weight: 400;
  src:
    local('GT-Walsheim-Regular-Oblique'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Regular-Oblique.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Regular-Oblique.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 500;
  src:
    local('GT-Walsheim-Medium'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Medium.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 600;
  src:
    local('GT-Walsheim-Bold'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Bold.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/gt-walsheim/GT-Walsheim-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Sailec';
  font-style: normal;
  font-weight: 300;
  src:
    local('Sailec-Light'),
    url('https://static.havenly.com/fonts/sailec/light.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Sailec';
  font-style: normal;
  font-weight: 400;
  src:
    local('Sailec-Regular'),
    url('https://static.havenly.com/fonts/sailec/regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Sailec';
  font-style: normal;
  font-weight: 500;
  src:
    local('Sailec-Medium'),
    url('https://static.havenly.com/fonts/sailec/medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Sailec';
  font-style: normal;
  font-weight: 600;
  src:
    local('Sailec-Bold'),
    url('https://static.havenly.com/fonts/sailec/bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Simula';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-variant: lining-nums;
  src:
    local('Simula-Book'),
    url('https://static.havenly.com/fonts/simula/Simula-Book.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/simula/Simula-Book.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Simula';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  font-variant: lining-nums;
  src:
    local('Simula-BookItalic'),
    url('https://static.havenly.com/fonts/simula/Simula-BookItalic.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/simula/Simula-BookItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'ABC Monument Grotesk';
  font-style: normal;
  font-weight: 400;
  src:
    local('ABCMonumentGrotesk-Regular'),
    url('https://static.havenly.com/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Regular.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'ABC Monument Grotesk';
  font-style: normal;
  font-weight: 500;
  src:
    local('ABCMonumentGrotesk-Medium'),
    url('https://static.havenly.com/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Medium.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'ABC Monument Grotesk';
  font-style: normal;
  font-weight: 600;
  src:
    local('ABCMonumentGrotesk-Bold'),
    url('https://static.havenly.com/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Bold.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/abc-monument-grotesk/ABCMonumentGrotesk-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  font-style:   normal;
  font-weight:  300;
  font-stretch: normal;
  src:
    local('Graphik-Light-Web'),
    url('https://static.havenly.com/fonts/graphik/Graphik-Light-Web.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/graphik/Graphik-Light-Web.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  font-style:   italic;
  font-weight:  300;
  font-stretch: normal;
  src:
    local('Graphik-LightItalic-Web'),
    url('https://static.havenly.com/fonts/graphik/Graphik-LightItalic-Web.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/graphik/Graphik-LightItalic-Web.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  font-style:   normal;
  font-weight:  400;
  font-stretch: normal;
  src:
    local('Graphik-Regular-Web'),
    url('https://static.havenly.com/fonts/graphik/Graphik-Regular-Web.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/graphik/Graphik-Regular-Web.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  font-style:   italic;
  font-weight:  400;
  font-stretch: normal;
  src:
    local('Graphik-RegularItalic-Web'),
    url('https://static.havenly.com/fonts/graphik/Graphik-RegularItalic-Web.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/graphik/Graphik-RegularItalic-Web.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  font-style:   normal;
  font-weight:  500;
  font-stretch: normal;
  src:
    local('Graphik-Medium-Web'),
    url('https://static.havenly.com/fonts/graphik/Graphik-Medium-Web.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/graphik/Graphik-Medium-Web.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  font-style:   italic;
  font-weight:  500;
  font-stretch: normal;
  src:
    local('Graphik-MediumItalic-Web'),
    url('https://static.havenly.com/fonts/graphik/Graphik-MediumItalic-Web.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/graphik/Graphik-MediumItalic-Web.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  font-style:   normal;
  font-weight:  600;
  font-stretch: normal;
  src:
    local('Graphik-Semibold-Web'),
    url('https://static.havenly.com/fonts/graphik/Graphik-Semibold-Web.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/graphik/Graphik-Semibold-Web.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  font-style:   italic;
  font-weight:  600;
  font-stretch: normal;
  src:
    local('Graphik-SemiboldItalic-Web'),
    url('https://static.havenly.com/fonts/graphik/Graphik-SemiboldItalic-Web.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/graphik/Graphik-SemiboldItalic-Web.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  font-style:   normal;
  font-weight:  700;
  font-stretch: normal;
  src:
    local('Graphik-Bold-Web'),
    url('https://static.havenly.com/fonts/graphik/Graphik-Bold-Web.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/graphik/Graphik-Bold-Web.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  font-style:   italic;
  font-weight:  700;
  font-stretch: normal;
  src:
    local('Graphik-BoldItalic-Web'),
    url('https://static.havenly.com/fonts/graphik/Graphik-BoldItalic-Web.woff2') format('woff2'),
    url('https://static.havenly.com/fonts/graphik/Graphik-BoldItalic-Web.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Tan Mon Cheri';
  font-style:   normal;
  font-weight:  700;
  font-stretch: normal;
  src:
    url('https://s3.amazonaws.com/static.havenly.com/fonts/tan-mon-cheri/tan-mon-cheri.woff2') format('woff2'),
    url('https://s3.amazonaws.com/static.havenly.com/fonts/tan-mon-cheri/tan-mon-cheri.woff') format('woff');
}

// contents of https://shared-assets.havenly.com/fonts/havenly-icons/style.css?version=1.0

@font-face {
  font-family: 'havenly-icons';
  src:  url('https://shared-assets.havenly.com/fonts/havenly-icons/fonts/havenly-icons.eot?ukmdem');
  src:  url('https://shared-assets.havenly.com/fonts/havenly-icons/fonts/havenly-icons.eot?ukmdem#iefix') format('embedded-opentype'),
    url('https://shared-assets.havenly.com/fonts/havenly-icons/fonts/havenly-icons.ttf?ukmdem') format('truetype'),
    url('https://shared-assets.havenly.com/fonts/havenly-icons/fonts/havenly-icons.woff?ukmdem') format('woff'),
    url('https://shared-assets.havenly.com/fonts/havenly-icons/fonts/havenly-icons.svg?ukmdem#havenly-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.havenly-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  font-family: 'havenly-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.havenly-icon.pin:before {
  content: '\e923';
}
.havenly-icon.dislike:before {
  content: '\e922';
}
.havenly-icon.unordered-list:before {
  content: '\e920';
}
.havenly-icon.ordered-list:before {
  content: '\e921';
}
.havenly-icon.expand:before {
  content: '\e91d';
}
.havenly-icon.collapse:before {
  content: '\e91e';
}
.havenly-icon.verified:before {
  content: '\e91f';
}
.havenly-icon.grid:before {
  content: '\e91c';
}
.havenly-icon.pencil:before {
  content: '\e91b';
}
.havenly-icon.utilities:before {
  content: '\e91a';
}
.havenly-icon.warning:before {
  content: '\e919';
}
.havenly-icon.arrow:before {
  content: '\e902';
}
.havenly-icon.search:before {
  content: '\e908';
}
.havenly-icon.caret:before {
  content: '\e90f';
}
.havenly-icon.paper-plane:before {
  content: '\e917';
}
.havenly-icon.clone-board:before {
  content: '\e918';
}
.havenly-icon.star-fill:before {
  content: '\e900';
}
.havenly-icon.star:before {
  content: '\e909';
}
.havenly-icon.happy-fill:before {
  content: '\e90a';
}
.havenly-icon.happy:before {
  content: '\e911';
}
.havenly-icon.sad-fill:before {
  content: '\e915';
}
.havenly-icon.sad:before {
  content: '\e916';
}
.havenly-icon.neutral-fill:before {
  content: '\e90e';
}
.havenly-icon.neutral:before {
  content: '\e90d';
}
.havenly-icon.compass:before {
  content: '\e903';
}
.havenly-icon.plus:before {
  content: '\e904';
}
.havenly-icon.close:before {
  content: '\e910';
}
.havenly-icon.add:before {
  content: '\e901';
}
.havenly-icon.eye:before {
  content: '\e905';
}
.havenly-icon.person:before {
  content: '\e906';
}
.havenly-icon.heart:before {
  content: '\e907';
}
.havenly-icon.history:before {
  content: '\e90b';
}
.havenly-icon.remove:before {
  content: '\e90c';
}
.havenly-icon.products-boards:before {
  content: '\e912';
}
.havenly-icon.question:before {
  content: '\e913';
}
.havenly-icon.recommendations:before {
  content: '\e914';
}

@import 'node_modules/bootstrap/scss/functions';
@import './variables/variables';
@import './mixins/mixins';
@import './retina-font';

@import 'node_modules/bootstrap/scss/reboot';
@import './bootstrap-grid';
@import 'node_modules/bootstrap/scss/modal';

// Bootstrap utils
@import 'node_modules/bootstrap/scss/utilities/text';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/utilities/background';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/utilities/position';
@import 'node_modules/bootstrap/scss/utilities/spacing';
@import 'node_modules/bootstrap/scss/utilities/sizing';

// Other
@import '~react-multi-carousel/lib/styles';

@import './variables/spacers';
@import './variables/typography';
@import './z-indices';

input:focus {
  outline: darken($white, 20%) auto 3px;
}

@import './custom-buttons';

body {
  width: 100%;

  > img {
    display: none;
  }
}

// this is a hack to correct expanded insplore style and room type filters when a branch journey banner is present
// see @components/shared/MobileAppConversionBanner/MobileAppConversionBanner.tsx
// and @components/pages/ExploreFeed/components/Filters/Filters.jsx
body.branch-banner-is-active { // sass-lint:disable-line no-qualifying-elements
  // also, don't overlay our mobile menu/header
  #HeaderWrapper {
    margin-top: 76px;
  }
  .ExpandedFiltersBranchHack {
    padding-top: 126px;
    @include min-width(small-tablet) {
      padding-top: 0;
    }
    @include min-width(large-tablet) {
      padding-top: 10px;
    }
  }
}

// this is for putting the snackbar alert component above all modals
#__next > div:last-of-type:not(.not-snackbar) {
  z-index: 9999999;
}

// modals need a high z-index but less than the snackbar alert
.modal {
  z-index: 2002; // 1 higher than the main nav
}

