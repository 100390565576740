@import './variables/variables';
@import 'node_modules/bootstrap/scss/functions';
@import './mixins/mixins';

$border-radius: 23px;
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
$standard-btn-box-shadow: 0 2px 4px 0 rgba(159, 159, 159, 0.2);
$emphasized-btn-box-shadow: 0 2px 4px 0 rgba(0,0,0, 0.3);

.h-btn-primary {
  @include custom-button-variant(
    // Colors
    $white,
    $black,
    $black,
    $standard-btn-box-shadow,

    // Hover Colors
    $white,
    $hoverGray,
    $hoverGray,
    $standard-btn-box-shadow,

    // Disabled Colors
    $white,
    $silver,
    $silver,
    $standard-btn-box-shadow,
  );
}

.h-btn-secondary {
  @include custom-button-variant(
    // Colors
    $black,
    $white,
    $white,
    $standard-btn-box-shadow,

    // Hover Colors
    $black,
    $white,
    $white,
    $emphasized-btn-box-shadow,

    // Disabled Colors
    $black,
    $white,
    $white,
    $standard-btn-box-shadow,
  );
}
