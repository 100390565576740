// Font stacks
$sans-serif: 'GT Walsheim', sans-serif;
$sans-serif-old: 'Sailec', 'sans-serif';
$simula-font: 'Simula', serif;
$headings-font-family: $sans-serif;
$font-family-base: $sans-serif;
$font-size-base: 0.875rem;

// Font sizes
$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-md: 1rem;
$font-size-lg: 1.125rem;
$small-font-size: $font-size-sm;
$h1-font-size: 2em;

// Font weights
$extra-light: 100;
$light: 300;
$normal: 400;
$heavy: 500;
$extra-heavy: 700;

// letter-spacing
$input-letter-spacing: 0.93px;