@use 'sass:map';

// This variable is also used in "src/styles/themeColors.scss"
$defaultThemeColors: (
  core1: #362b24,
  core2: #ffffff,
  core3: #e8e5de,
  core4: #304561,
  core5: #c2b04a,
  core6: #e0e5f0,
  core7: #ed6b4d,
  core8: #e59400,
  tint1: #f3f0eb,
  tint2: #cbdfe9,
  tint3: #5b7fb2,
  tint4: #e0dfaf,
  tint5: #dece71,
  tint6: #fac8a9,
  tint7: #f59582,
  tint8: #ecd790,
  tint9: #ffe657,
  tint10: #e5eaf1,
  shade1: #beac9e,
  shade2: #9c9e88,
  shade3: #686230,
  shade4: #c9c8ff,
  shade5: #8690ff,
  shade6: #c25944,
  shade7: #8c4d2e,
  shade8: #a3681a,
  shade9: #a9c2e0,
  charcoal: #191817,
  gray1: #eeeeee,
  gray2: #dddddd,
  gray3: #bbbbbb,
  gray4: #999999,
  gray5: #888888,
  gray6: #6b6b6b,
  charcoalNew: #171717,
  pebbleTint1: #F3F0EB
);

// export all color variables to Js/Ts
:export {
  @each $key, $value in $defaultThemeColors {
    #{$key}: $value;
  }
}
